export let invalidForm = "Please fill form correctly.";
//  User error message
export let userCreated = "Account created successfully!";
export let userUpdated = "Account updated successfully!";
export let checkCompany = "Company already registered.";
export let emailExist = "This email is already registered.";
export let packageExtensionLimitError ="This package extension limit is less than previous package created extension. So you can't change the package";
//  Email template error message
export let emailTemplateCreated = "Email created successfully!";
export let textareaContent = "Content can't be remain blank!";
export let imageUpload = "Please upload the image";
// Change Password message
export let oldPasswordMismatch = "Old Password mismatch, Please enter correct password";
export let passwordMismatch = "New and Confirm Password mismatch";
export let passwordChanged = "Password changed successfully!";
export let invalidPassword="New Password must contain minimum 8 characters,  1 lower case,1 upper case, and 1 special character";
// Profile message
export let profileUpdated = "Profile updated successfully!";
export let checkUsername = "Username already exists.";
//email category message
export let emailCategoryCreated = "Email Category created successfully!";
export let emailCategoryUpdated = "Email Category updated successfully!";
//Email category
export let categoryAlwaysExist = "Category already exists,if you want to change something then Please inactive the first one and then create";
export let mailSendError = "Not sending email";
export let parentalControlActive = `Parental Control option lets you restrict certain content to be displayed on Digivive.
This ensures that your child only has access to only that content which is suitable for their viewing. Turning
on this option will automatically hide content that is not suitable for children under 13 years of age.`;
export let parentalControlInActive = `Parental Control is enabled in your account. Content not suitable for children under 13 years of age is hidden. If you disable Parental Control, all content on nexGTv will be visible and available to watch.
Please click on Continue to disable Parental Control.`;

